<template>
  <section class="bg-gray-50 dark:bg-gray-900">
    <div
      class="flex flex-col items-center justify-center gap-4 px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div>
        <h1 class="text-5xl font-bold" style="color: #ad1457">{{ title }}</h1>
      </div>

      <div class="flex flex-col md:flex-row justify-center items-center max-w-screen-md w-full gap-4 md:gap-0">

        <div
          class="corn-logo mx-auto bg-contain bg-center bg-no-repeat rounded-3xl bg-img h-40 md:h-full order-2 md:order-1"
          style="width: 185px;"
        ></div>

        <div
          class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 order-1 md:order-2"
        >
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <form class="space-y-4 md:space-y-6" @submit.prevent="submit">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >Email</label
                >
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                  placeholder="name@company.com"
                  autocomplete="username"
                  required
                />
              </div>
              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >Password</label
                >
                <input
                  type="password"
                  v-model="password"
                  id="password"
                  placeholder="••••••••"
                  autocomplete="current-password"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                  required
                />
              </div>
              <button
                type="submit"
                class="w-full text-white bg-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, watch, inject } from "vue";
import { notify } from "@kyvg/vue3-notification";
import { useRoute, useRouter } from 'vue-router'
import { authApi } from "@/services/authApi";

import { useLoading } from 'vue-loading-overlay'
import LogFS from "@/firebases/LogFS";

const router = useRouter()
const route = useRoute()

const email = ref(null);
const password = ref(null);

const title = process.env.VUE_APP_APP_TITLE;

const $loading = useLoading({});

onMounted(() => {
});

const submit = async () => {
  const loader = $loading.show({});

  try {
    const authentication = await authApi.loginUser(email.value, password.value);
    // console.log("authentication", authentication)
    if (authentication.status) {
        loader.hide();
        router.push("/");
    } else {
      const error = authentication.data;
      for (const [key, value] of Object.entries(error)) {
        notify({
          group: "bottom-center-group",
          type: "error",
          title: "There was an error!",
          text: value + " [ " + key + " ]",
        });
      }

      console.error("There was an error!", error);
      loader.hide();
    }
  } catch (error) {
    notify({
      group: "bottom-center-group",
      type: "error",
      title: "There was an error!",
      text: "contact administrator !",
    });
    
    LogFS.createLog(error);
    console.error("There was an error!", error);
    loader.hide();
  }
};
</script>
<style scoped>
.corn-logo {
  background-image: url("~@/assets/images/login-logo.png");
  background-color: #ad1457;
}
</style>
