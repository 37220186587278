import LogFS from "@/firebases/LogFS";
import { useContextStore } from "@/stores/context";
import { notify } from "@kyvg/vue3-notification";

export const WorkLocationApi = {
  async accessToken() {
    const contextStore = useContextStore();
    const access_token = contextStore.getAccessToken;
    return access_token;
  },
  async getWorkLocations() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    
    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/work-locations", requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getWorkLocations) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  }
};
  