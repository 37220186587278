import { useContextStore } from "@/stores/context";
import { useActivityAsyncStore } from "@/stores/activityAsync";
import { useWorkLocationStore } from "@/stores/workLocation";

import axios, { AxiosError } from 'axios';
import { utils } from "@/utilities";
import ActivityFS from "@/firebases/ActivityFS";
import LogFS from "@/firebases/LogFS";

import { notify } from "@kyvg/vue3-notification";

export const api = {
  async accessToken() {
    const contextStore = useContextStore();
    const access_token = contextStore.getAccessToken;
    return access_token;
  },
  async getActivitiesToday(day) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/activities-today?date=" + day, requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getActivitiesToday) Please reload page !",
      });
      
      LogFS.createLog(error);
      return [];
    }
  },
  async getVillages(query) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/villages?text=" + (query ?? ""), requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data.map((d) => {
          return {
            value: d.id,
            label: d.name + '( ' + d.subdistrict + ', ' + d.district + ', ' + d.province + ' )',
            province: d.province,
            subdistrict: d.subdistrict,
            district: d.district,
          };
        });
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getVillages) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },
  async getFarmers(query) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/farmers?text=" + (query ?? ""), requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data.map((d) => {
          return {
            value: d.id,
            label: d.name + '( ' + d.subdistrict + ', ' + d.district + ', ' + d.province + ' )',
          };
        });
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getFarmers) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },
  async getDealers(query) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/dealers?text=" + (query ?? ""), requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data.map((d) => {
          return {
            value: d.id,
            label: d.parent_id ? d.name + ' (Subdealer)' : d.name + ' (Dealer)',
            parent_id: d.parent_id,
          };
        });
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getDealers) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },
  async getHybrids(query) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/hybrids?text=" + (query ?? ""), requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data.map((d) => {
          return { value: d.id, label: d.name };
        });
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getHybrids) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },
  async getStaffs(query) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/staffs?text=" + (query ?? ""), requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data.map((d) => {
          return { value: d.id, label: d.name };
        });
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getStaffs) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },
  async getCompetitors(query) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/competitors?text=" + (query ?? ""), requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data.map((d) => {
          return { value: d.id, label: d.name };
        });
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getCompetitors) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },

  async getTodayClockInOut() {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/clock-in-out", requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && Object.keys(data).length > 0) {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (getTodayClockInOut) Please reload page !",
      });

      LogFS.createLog(error);
      return [];
    }
  },

  async clockInOut(form) {
    if (form.formClockIn || form.formClockOut) {
      const activityAsyncStore = useActivityAsyncStore();
      const syncAsync = activityAsyncStore.getSyncAsync;

      const hashing = form.formClockOut?.hashing ? form.formClockOut.hashing : form.formClockIn?.hashing
      if (hashing) {
        if (syncAsync.includes(hashing)) {
          return { status: false, data: { 'message': 'Processing..., Please reload page.' } };
        } else {
          activityAsyncStore.addSyncAsync(hashing);
        }
      }
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      headers: myHeaders,
      method: "POST",
      body: JSON.stringify({
        ...form,
      }),
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/clock-in-out", requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      // > 400 ignored already clock in out (400)
      if (response.status > 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && data.workLocation && (data.workLocation instanceof Object) && !Array.isArray(data.workLocation)) {
        const workLocationStore = useWorkLocationStore();
        await workLocationStore.addNewWorkLocation(data.workLocation);
      }

      return { status: response.ok, data: data };
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (clockInOut) Please reload page !",
      });

      LogFS.createLog(error);
      return { status: false, data: error };
    }
  },

  async addUnPlanActivity(form) {
    const activityAsyncStore = useActivityAsyncStore();
    const syncAsync = activityAsyncStore.getSyncAsync;

    if (form?.id && isNaN(form.id)) {
      if (syncAsync.includes(form.id)) {
        return { status: false, data: { 'message': 'Processing..., Please reload page.' } };
      } else {
        activityAsyncStore.addSyncAsync(form.id);
      }

      LogFS.createLogSync(form.id, syncAsync);
    }

    const formData = utils.convertToFormData(form);
    formData.append('_method', 'PUT');

    try {
      const response = await axios.post(process.env.VUE_APP_API_URL + "/api/pwa/add-unplanned-activity/", formData, {
        headers: {
          'Authorization': "Bearer " + await this.accessToken(),
          'Content-Type': 'multipart/form-data',
        }
      }).catch(function (error) {
        throw new AxiosError(error.message, error.code, error.config, error.request, error.response);
      });

      const data = response.data;
      LogFS.createLogRequest(form, data);

      if (form?.id && isNaN(form.id) && response.status === 200) {
        ActivityFS.delete(form.id);
      }

      return { status: response.status === 200, data: data };
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (addUnPlanActivity) Please reload page !",
      });

      LogFS.createLog(error);
      return {
        status: false, data: {
          error: error.message
        }
      };
    }
  },
  async checkInActivity(form) {
    // const requestKeys = ["work_location", "name", "latitude", "longitude", "remark", "current_latitude", "current_longitude", "distance", "sale_schedule", "sale_route_line_id"];
    // if (utils.requestKeys(form, requestKeys) === false) {
    //   return { status: false, data: { 'message': 'missing some key' } };
    // }

    const activityAsyncStore = useActivityAsyncStore();
    const syncAsync = activityAsyncStore.getSyncAsync;

    if (form?.hashing) {
      if (syncAsync.includes(form.hashing)) {
        return { status: false, data: { 'message': 'Processing..., Please reload page.' } };
      } else {
        activityAsyncStore.addSyncAsync(form.hashing);
      }
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + await this.accessToken());
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(form),
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/pwa/activity/check-in", requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      if (response.status >= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.ok && form.sale_schedule) {
        const newData = {
          ...ActivityFS.getById(form.sale_schedule),
          sales_checkin: data.sales_checkin,
        };

        ActivityFS.update(form.sale_schedule, newData);
      }

      return { status: response.ok, data: data };
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (checkInActivity) Please reload page !",
      });

      LogFS.createLog(error);
      return { status: false, data: error };
    }
  },
  async checkOutActivity(form) {
    // append sales_schedule_id to checkout
    form.sale_schedule = { ...form.sale_schedule, sales_schedule_id: form?.sale_schedule?.id ?? 0 };

    const activityAsyncStore = useActivityAsyncStore();
    const syncAsync = activityAsyncStore.getSyncAsync;

    if (form?.hashing) {
      if (syncAsync.includes(form.hashing)) {
        return { status: false, data: { 'message': 'Processing..., Please reload page.' } };
      } else {
        activityAsyncStore.addSyncAsync(form.hashing);
      }
    }

    const formData = utils.convertToFormData(form);
    formData.append('_method', 'PUT');

    let path = '';
    if (form.activity_type == "Dealer/Sub Meeting") {
      path = 'meet-dealer-subdealers';
    } else if (form.activity_type == "Spot Sale") {
      path = 'spot-sales';
    } else if (form.activity_type == "Caravan") {
      path = 'caravans';
    } else if (form.activity_type == "Farmer Meeting") {
      path = 'farmer-meetings';
    } else if (form.activity_type == "Contact") {
      path = 'contacts';
    } else if (form.activity_type == "Field Day") {
      path = 'field-days';
    } else if (form.activity_type == "Corn Tour") {
      path = 'focus-villages';
    } else if (form.activity_type == "Harvest") {
      path = 'harvests';
    } else if (form.activity_type == "Others") {
      path = 'other-commercials';
    }

    try {
      const response = await axios.post(process.env.VUE_APP_API_URL + "/api/pwa/sales-activities/" + path, formData, {
        headers: {
          'Authorization': "Bearer " + await this.accessToken(),
          'Content-Type': 'multipart/form-data',
        }
      }).catch(function (error) {
        throw new AxiosError(error.message, error.code, error.config, error.request, error.response);
      });

      const data = response.data;

      if (response.status === 200 && form?.sale_schedule?.id) {
        const newData = {
          ...ActivityFS.getById(form.sale_schedule.id), // expected has id
          sales_checkout: data.sales_checkout,
        };

        ActivityFS.update(form.sale_schedule.id, newData);
      }

      return { status: response.status === 200, data: data };
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (checkOutActivity) Please reload page !",
      });

      LogFS.createLog(error);
      return {
        status: false, data: {
          error: error.message
        }
      };
    }
  }
};
