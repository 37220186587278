import LogFS from "@/firebases/LogFS";
import { useContextStore } from "@/stores/context";
import { utils } from "@/utilities";

import { notify } from "@kyvg/vue3-notification";

export const authApi = {
  async accessToken() {
    const contextStore = useContextStore();
    const access_token = contextStore.getAccessToken;
    return access_token;
  },
  async loginUser(email, password) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    try {
      const response = await fetch(process.env.VUE_APP_API_URL + "/api/auth/login", requestOptions);
      const isJson = response.headers.get("content-type")?.includes("application/json");
      const data = isJson && await response.json();

      // check for error response
      if (response.ok) {
        const contextStore = useContextStore();
        const newData = { ...data, created_at: new Date().getTime() };
        $cookies.set('auth', JSON.stringify(newData), newData.expires_in);

        contextStore.updateAuth(newData);
        contextStore.updateUser(newData.user)

        return { status: true, data: newData };
      }

      // if (response.status >= 400) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      return { status: false, data: data };
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: error.message + " (loginUser) Please reload page !",
      });

      LogFS.createLog(error);
      return { status: false, data: error };
    }
  },
  async refreshUser() {
    const contextStore = useContextStore();
    const auth = contextStore.getAuth;

    if (auth && utils.isMoreThanDays(new Date(), new Date(auth.created_at))) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + await this.accessToken());

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
      };

      try {
        const response = await fetch(process.env.VUE_APP_API_URL + "/api/auth/refresh", requestOptions);
        const isJson = response.headers.get("content-type")?.includes("application/json");
        const data = isJson && await response.json();

        // check for error response
        if (response.ok) {
          const newData = { ...data, created_at: new Date().getTime() };
          $cookies.set('auth', JSON.stringify(newData), newData.expires_in);

          contextStore.updateAuth(newData);
          contextStore.updateUser(newData.user)

          return { status: true, data: newData };
        }

        if (response.status >= 400) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        return { status: false, data: data };
      } catch (error) {
        notify({
          group: "bottom-center-group",
          type: "error",
          title: error.message + " (refreshUser) Please reload page !",
        });

        LogFS.createLog(error);
        return { status: false, data: error };
      }
    }

    return { status: false, data: null };
  }
};
